<div class="footer-section">
    <div class="container">
     <div class="row">
        <div class="col-md-4">
            <div class="phone-number">
                <p><i class="bi bi-telephone"></i> {{setting.admin_contact_phone_number}}</p>
                <p><i class="bi bi-geo-alt"></i> Maxima Logistics Pte Ltd, 62, UBI Road 1, Oxley Bizhub 2, #08-06, Singapore 408734</p>
                <!-- <p><i class="bi bi-envelope"></i> {{setting.admin_contact_email}}</p> -->
                <p><i class="bi bi-envelope"></i> admin@hungryfoods.sg</p>
                <p> <i class="bi bi-clock"></i> ALL Days 10:00 - 18:00</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row mk-row">
                <!-- <div class="footer-logo col-12 col-sm-12 col-md-6 col-lg-3"> -->
                  <a href="/" class="footer-logo-url">
                      <!-- <img src="../../../assets/images/landing_page/logo_only.png" class="lazyload footer-logo" alt="Deliver.sg" > -->
                      <img class="lazyload" [src]="IMAGE_URL + 'Hungry_Foods/Hungry_foods_footer_logo.webp'" alt="Deliver.sg" height="auto" width="150px">
                 
                    </a>
                <!-- </div> -->
            </div>
            <!-- <div class="row"> -->
                <p style="text-align: center;"><i class="bi bi-facebook" style="margin: 5px;font-size: 28px;"></i><i class="bi bi-twitter" style="margin: 5px;font-size: 28px;"></i><i class="bi bi-linkedin" style="margin: 5px;font-size: 28px;"></i></p>
                <!-- <p style="margin: 5px;font-size: 28px;"><i class="bi bi-twitter"></i></p>
                <p style="margin: 5px;font-size: 28px;"><i class="bi bi-linkedin"></i></p> -->
            <!-- </div> -->
        </div>
        <div class="col-md-4">
            <p>SUBSCRIBE NEWSLETTER</p>
            <p>Newsletter updates from your favourite product</p>
            <input type="text" class="form-control" placeholder="Enter Mail" id="newsletter" name="newsletter">
        </div>
     </div>
     <p class="footer-line"></p>
     <div class="row">
        <div class="col-md-6">
            <p>Hungry Foods@2024. All Rights Reserved</p>
        </div>
        <div class="col-md-6" style="text-align: right;">
            <!-- <p style="float: right;">Deliversg @2024. All Rights Reserved</p> -->
            <img src="../../../assets/images/landing_page/footer-1.png" alt="payments" width="25%">
        </div>
     </div>
    </div>
</div>

<!--
<div class="footer-section py-5">
  <div class="container">

          <div class="row ">
            <div class="footer-logo col-12 col-sm-12 col-md-6 col-lg-3">
              <a href="/">
                  <img [src]="IMAGE_URL + 'web_images/user_logo.png'" class="lazyload" alt="Deliver.sg" height="60px" width="60px">
              </a>
            </div>
              <div class="col-12 col-sm-4 col-md-6 col-lg-3 footer-logo-section footer-block d-flex justify-content-md-start">
                  <div class="phone-number">
                      <span>{{setting.admin_contact_phone_number}}</span>
                      <span>{{setting.admin_contact_email}}</span>
                  </div>
              </div>
              <div class="col-12 col-sm-4 col-md-6 col-lg-3 footer-link-list footer-block">
                <ul class="list-group">
                    <li class="list-group-item bg-transparent py-0"><a href="/legal/user-privacy-policy" target="_blank">{{'label-title.privacy-policy' | translate}}</a></li>
                    <li class="list-group-item bg-transparent py-0"><a href="/legal/user-terms-conditions" target="_blank">{{'label-title.terms-conditions' | translate}}</a></li>
                </ul>
              </div>
              <div class="col-12 col-sm-4 col-md-6 col-lg-3 footer-social footer-block">
                <div class="app-link d-flex align-item-center flex-sm-column flex-row flex-md-row">
                    <div class="google-app mr-30">
                        <a href="https://play.google.com/store/apps/details?id=com.elluminatiinc.edelivery" target="_blank">
                            <img width="130px" height="40px" class="lazyload" src="../../../assets/images/g-play.png" alt="Deliver.sg">
                        </a>
                    </div>
                    <div class="ios-app">
                        <a href="https://apps.apple.com/us/app/edelivery-user/id1276529954?ls=1"  target="_blank">
                            <img width="130px" height="40px" class="lazyload" src="../../../assets/images/ios-store.png" alt="Deliver.sg">
                        </a>
                    </div>
                </div>
                <div class="social-link-list hide">
                    <ul class="d-flex align-item-center flex-wrap">
                        <li>
                            <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><img src="../../../assets/images/fb-icon.svg" alt=""></a>
                        </li>
                    </ul>
                </div>

                <div class="lang-btn hide">
                        <select name="month" id="lang" class="form-control">
                            <option value="">{{'label-title.lang' | translate}}</option>
                            <option value="january">1</option>
                            <option value="february">2</option>
                        </select>
                </div>

              </div>
          </div>

      <div class="lang-f">
          <ul>
            <div class="row ">
              <div class="col-xl-1 col-md-2 col-3" *ngFor="let item of langs; let i = index">
                <li (click)="change_langauge(item.code, i)" class="mb-1"><a [class.theme-text]="item.code === selectedLang" href="javascript:void(0)">{{item.name}}</a></li>
              </div>
            </div>
          </ul>
      </div>
  </div>
</div> -->
