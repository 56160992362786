import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  Subscription } from 'rxjs';
import { CountryModel } from 'src/app/models/country.model';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { LocationModel, LocationService } from 'src/app/services/location.service';
import { Helper } from 'src/app/shared/helper';
import { SetttingModel } from 'src/app/models/setting.model';
import { DEFAULT_IMAGE } from 'src/app/constants/constants';
import { NotifiyService } from 'src/app/services/notifier.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig } from 'angularx-social-login';
import { environment } from 'src/environments/environment';

declare var $:any;
declare var google:any;

export enum VERIFICATION_TYPE {REGISTER,AUTH}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit,OnDestroy {
  loginForm :FormGroup;
  signupForm :FormGroup;
  is_invalid_email = false;
  forgotPasswordForm :FormGroup;
  otpForm :FormGroup;
  resetPasswordForm :FormGroup;
  verificationForm :FormGroup = new FormGroup({
    sms_otp:new FormControl(null),
    email_otp:new FormControl(null)
  });

  IMAGE_URL = environment.IMAGE_URL;
  TERMS_URL = 'legal/user-terms-conditions';
  PRIVACY_URL = 'legal/user-privacy-policy';
  DEFAULT_USER_PROFILE = DEFAULT_IMAGE.USER_PROFILE;
  DEFAULT_ITEM_IMAGE = DEFAULT_IMAGE.PRODUCT;

  userSubscription:Subscription;
  cartSubscription:Subscription;
  verificationSubscription:Subscription;
  user:UserModel;

  countries:Array<CountryModel> = [];
  total_items_in_user_cart: number = 0;
  items_in_user_cart = [];
  is_referral_applied:boolean = false;
  referral_error = "";
  password_error = "";
  verification_error = "";
  resetPasswordID = "";
  resetPasswordToken = "";
  setting_data:SetttingModel = new SetttingModel();
  verification_type = null;
  verificationData = {
    email_otp : null,
    sms_otp : null
  }
  is_invalid_otp: boolean = false
  current_location:LocationModel = new LocationModel();
  is_social_login: boolean = false;
  maxNumberLength: Number
  minNumberLength: Number
  clickTimeout = null;
  address_error = this.helper.trans.instant("label-title.select-address");

  @ViewChild('address', { static: true }) address: ElementRef;

  constructor(public helper:Helper,
    public _cartService:CartService,
    public _locationService: LocationService,
    private _socialAuthService: SocialAuthService,
    private renderer2: Renderer2,
    public _commanService:CommonService,
    private _notifierService:NotifiyService,
    public _authService:AuthService) {}


  // Init App Data



  ngOnInit(): void {    
    this._initForms();
    this.setting_data = this._commanService.settings;

    this.userSubscription = this._authService.loginObservable.subscribe((user)=>{
        this.user = user;
        if(user){
          this._authService.check_verification(this.user,this._commanService.settings);
        }
        this._cartService.update_local_cart();
  });

    this.cartSubscription = this._cartService.cartObservable.subscribe((cart)=>{
      this.total_items_in_user_cart = 0;
      this.items_in_user_cart = [];
      if(cart && (this._cartService.user_cart.cart_data.cart.length || cart.cart_data.cart.length)){
        this._cartService.user_cart.cart_data.cart.forEach(element => {
          this.total_items_in_user_cart = this.total_items_in_user_cart + element.items.length
          this.items_in_user_cart.push(element)
        });
        console.log(this.items_in_user_cart)
      }else{
        this.total_items_in_user_cart = 0
        this.items_in_user_cart = []
      }
   });

   this.verificationSubscription = this._authService.verificationObservable.subscribe((is_required_verification)=>{
      if(is_required_verification){
        this._authService.user_generate_otp({
          email:this._authService.user.email,
          phone:this._authService.user.phone,
          country_phone_code:this._authService.user.country_code
        }).then(otpData=>{
          if(otpData.success){
            this.verificationData = {email_otp: otpData.otp_for_email,sms_otp:otpData.otp_for_sms}
            this.verification_type = VERIFICATION_TYPE.AUTH;
            this.verificationForm.reset();
            $(".custom-model-main").removeClass('model-open');
            $("body").addClass('model-open');
            $('#verification-popup').addClass('model-open');
          }
        })

      }
   })

   this.loadGoogleScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBsZwP9rsS3iTBhn65U2lw3mzAWSSuvux8&libraries=places,drawing").then(()=>{
      this._initAutoComplete();
    })
  }

  private loadGoogleScript(url) {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = ``;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(document.body, script);
    })
  }


  _initForms(){

    this.signupForm  = new FormGroup({
      first_name:new FormControl(null,Validators.required),
      last_name:new FormControl(null,Validators.required),
      email:new FormControl(null,Validators.required),
      phone:new FormControl(null,Validators.required),
      country_phone_code:new FormControl(null,Validators.required),
      country_code:new FormControl(null,Validators.required),
      currency:new FormControl(null,Validators.required),
      password:new FormControl(null,[Validators.minLength(6)]),
      cart_unique_token:new FormControl(null,Validators.required),
      is_phone_number_verified:new FormControl(false,Validators.required),
      is_email_verified:new FormControl(false,Validators.required),
      is_term_checked:new FormControl(true,Validators.required),
      login_by:new FormControl('manual'),
      referral_code:new FormControl(null),
      city:new FormControl(null),
      social_id:new FormControl(null),
      address:new FormControl(null),
    })

    this.loginForm = new FormGroup({
      username:new FormControl(null,[Validators.required]),
      password:new FormControl(null,Validators.required)
    })

    this.forgotPasswordForm = new FormGroup({
      phone:new FormControl(null,[Validators.required])
    })

    this.otpForm =  new FormGroup({
      phone:new FormControl(null,[Validators.required]),
      otp:new FormControl(null,[Validators.required])
    })

    this.resetPasswordForm = new FormGroup({
      password:new FormControl(null,[Validators.required]),
      confirm_password:new FormControl(null,[Validators.required])
    })


    this.verificationForm =  new FormGroup({
      sms_otp:new FormControl(null,Validators.required),
      email_otp:new FormControl(null,Validators.required)
    })
  }

  // Header and Cart Flow

  _initAutoComplete(){

      if(localStorage.getItem('current_location')){
        this.current_location = JSON.parse(localStorage.getItem('current_location'))
        this._locationService.current_location = JSON.parse(localStorage.getItem('current_location'))
      }

      var autocompleteElm = this.address.nativeElement;
      let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {
        types: [],
        // componentRestrictions: { country: country_code }
      });
      autocomplete.addListener('place_changed', () => {
        this.helper._loader.isLoading = true;
        var place = autocomplete.getPlace();
        var curLocation:LocationModel = new LocationModel();
        curLocation.latitude = place.geometry.location.lat();
        curLocation.longitude = place.geometry.location.lng();
        curLocation.address = place['formatted_address'];
        place['address_components'].forEach(element => {
            var type = element.types[0]
            switch (type) {
                case 'country':
                    curLocation.country_name = element.long_name;
                    curLocation.country_code = element.short_name;
                    break;
                case 'administrative_area_level_1':
                    curLocation.state_code = element.short_name;
                    curLocation.state_name = element.long_name;
                    break;
                case 'administrative_area_level_2':
                    curLocation.city_name = element.short_name;
                    break;
                case 'postal_code':
                    break;
                default:
                    break;
            }
        });
        this._locationService.current_location  = curLocation;

        // this._cartService.update_address(curLocation.latitude, curLocation.longitude, curLocation.address).then(is_update => {
        // //   this.helper.ngZone.run(() => {
        // //     if (is_update) {
        // //       // Display
        // //       this.address_error = ""
        // //       window.location.reload();
        // //     } else {
        // //       this.address_error = this.helper.trans.instant("label-title.delivery-not-available");
        // //     }
        // //   });
        // })
      });
  }

  increaseValue(product_id, itemIdx, item): void {
    item.quantity++;
    if (this.clickTimeout) {
      this.setClickTimeout(() => this.handleIncreaseValue(product_id, itemIdx, item));
    } else {
      this.setClickTimeout(() => this.handleIncreaseValue(product_id, itemIdx, item));
    }
  }

  handleIncreaseValue(product_id, itemIdx, item){
    const update_qty = item.quantity - this._cartService.user_cart.cart_data.cart[0].items[itemIdx].quantity;
    this._cartService.increase_qty(product_id, itemIdx, update_qty)
  }

  decreaseValue(product_id, itemIdx, item): void {
    if (item.quantity > 0) {
      item.quantity--;
    } else {
      item.quantity = 0;
    }
    if (this.clickTimeout) {
      this.setClickTimeout(() => this.handleDecreaseValue(product_id, itemIdx, item));
    } else {
      this.setClickTimeout(() => this.handleDecreaseValue(product_id, itemIdx, item));
    }
  }

  registerasrestaurent() {
    let url = this.setting_data.store_base_url + 'store/login'
    window.open(url, '_blank')
  }

  handleDecreaseValue(product_id, itemIdx, item){
    const update_qty = this._cartService.user_cart.cart_data.cart[0].items[itemIdx].quantity - item.quantity;
    this._cartService.decrease_qty(product_id, itemIdx, update_qty)
  }

  set_current_location(){
    localStorage.removeItem('current_location')
    this._locationService.set_current_location().then(location=>{
      if(location){
          this.current_location = JSON.parse(JSON.stringify(location));
      }else{
        this._notifierService.showNotification('error','Please Enable Location');
      }
    }).catch(() => {
      this._notifierService.showNotification('error','Please Enable Location');
    })
  }

  goToCheckout(){
    this.helper._route.navigate(['/checkout']);
  }

  openModel(id){
    this.helper.openModel(id);
  }

  openSignupForm(){
    this._commanService.get_country_list().then(data=>{
      this.countries = data;
      
      console.log(JSON.stringify(this.countries));

      this.minNumberLength = this.countries[0].minimum_phone_number_length
      this.maxNumberLength = this.countries[0].maximum_phone_number_length
      if(this.countries.length){
        this.signupForm.patchValue({
          country_code:this.countries[0].country_code,
          country_phone_code:this.countries[0].country_phone_code,
          currency:this.countries[0].currency_code,
          cart_unique_token:this.helper.cart_unique_token
        })
        this.helper.openModel('sign-up-popup');
      }
    })
  }

  // Auth Flow

  signIn(){
    if(this.loginForm.invalid){
      this.loginForm.markAllAsTouched();
      return;
    }
    this._authService.user_login({username:this.loginForm.value.username,password:this.loginForm.value.password,uuid:this.helper.cart_unique_token}).then(islogin=>{
      if(islogin){
        $(".signinclose").click();
        this._cartService.user_cart.cart_data.destination_addresses[0].user_details.name = this._authService.logginUser.first_name + this._authService.logginUser.last_name
        this._cartService.user_cart.cart_data.destination_addresses[0].user_details.phone = this._authService.logginUser.phone
        this.loginForm.reset();
      }
    });
  }

  signOut(){
    this._authService.user_logout();
    this.helper._route.navigate(['/'])
    window.location.reload()
  }

  // openModel(id){
  //   $(".custom-model-main").removeClass('model-open');
  //   $("body").addClass('model-open');
  //   $('#'+id).addClass('model-open');
  // }

  signUp(){

      if(this.signupForm.invalid && (!this.signupForm.value.social_id && this.signupForm.value.password !== null)){
          this.signupForm.markAllAsTouched();
          return;
      }

      this.is_invalid_email = false;

      var email_validation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!email_validation.test(this.signupForm.value.email)){
          this.is_invalid_email = true;
          return;
      }

      if(this.setting_data.is_user_mail_verification || this.setting_data.is_user_sms_verification){
        this._authService.user_generate_otp({
          email:this.signupForm.value.email,
          phone:this.signupForm.value.phone,
          country_phone_code:this.signupForm.value.country_code
        }).then(otpData=>{
          if(otpData.success){
            this.verificationData = {email_otp: otpData.otp_for_email,sms_otp:otpData.otp_for_sms};
            this.verification_type = VERIFICATION_TYPE.REGISTER;
            this.verificationForm.reset();
            $(".custom-model-main").removeClass('model-open');
            $("body").addClass('model-open');
            $('#verification-popup').addClass('model-open');
          }
        })
      }else{
        this.register();
      }

  }

  resendOtp(){
    if(this.setting_data.is_user_mail_verification || this.setting_data.is_user_sms_verification){
      this._authService.user_generate_otp({email:this._authService.user.email,phone:this._authService.user.phone,country_phone_code:this._authService.user.country_code}).then(otpData=>{
        if(otpData.success){
          this.verificationData = {email_otp: otpData.otp_for_email,sms_otp:otpData.otp_for_sms}
        }
      })
    }
  }

  verifyUser(){
    if(this.verification_type == null){
        return;
    }

    this.verification_error = ""
    var is_sms_verified = false;
    var is_email_verified = false;

    if(!this.setting_data.is_user_sms_verification || this._authService.user.is_phone_number_verified){
      this.verificationForm.patchValue({sms_otp:"123456"})
      is_sms_verified = true;
    }

    if(!this.setting_data.is_user_mail_verification || this._authService.user.is_email_verified){
      this.verificationForm.patchValue({email_otp:"654321"})
      is_email_verified = true;
    }

    if(this.verificationForm.invalid){
      this.verificationForm.markAllAsTouched();
      return;
    }

    if(!is_sms_verified){
      is_sms_verified = this.verificationData.sms_otp === this.verificationForm.value.sms_otp;
    }
    if(!is_email_verified){
      is_email_verified = this.verificationData.email_otp === this.verificationForm.value.email_otp;
    }

    if((this.setting_data.is_user_sms_verification && is_sms_verified) || (this.setting_data.is_user_mail_verification && is_email_verified)){
      // $('#verificationclose').click()
      $(".custom-model-main").removeClass('model-open');
      $("body").removeClass('model-open');
      $("body").removeClass('model-open');
      $('#verification-popup').removeClass('model-open');
      switch (this.verification_type) {
        case VERIFICATION_TYPE.REGISTER:
          this.verification_type = null;
          this.register();
          break;
        case VERIFICATION_TYPE.AUTH:
          var json = {};
          json['user_id'] = this._authService.user._id;
          json['server_token'] = this._authService.user.server_token;

          if (this.setting_data.is_user_mail_verification) {
            json['email'] = this._authService.user.email
            json['is_email_verified'] = true;
          }

          if (this.setting_data.is_user_sms_verification) {
            json['phone'] = this._authService.user.phone
            json['is_phone_number_verified'] = true
          }
          this._authService.user_verify_otp(json);

          this.verification_type = null;
          break;
        default:
          this.verification_type = null;
          break;
      }
    }else{
      this.verification_error = this.helper.trans.instant("label-title.verification-error");
    }

  }

  private register(){

      const signUpForm = new FormData();
      signUpForm.append('first_name',this.signupForm.value.first_name);
      signUpForm.append('last_name',this.signupForm.value.last_name);
      signUpForm.append('email',this.signupForm.value.email);
      signUpForm.append('phone',this.signupForm.value.phone);
      signUpForm.append('country_phone_code',this.signupForm.value.country_phone_code);
      signUpForm.append('country_code',this.signupForm.value.country_code);
      signUpForm.append('currency',this.signupForm.value.currency);
      signUpForm.append('cart_unique_token',this.signupForm.value.cart_unique_token);
      signUpForm.append('referral_code',this.signupForm.value.referral_code);
      signUpForm.append('city',this.signupForm.value.city);
      signUpForm.append('login_by',this.signupForm.value.login_by);
      signUpForm.append('address',this.signupForm.value.address);
      signUpForm.append('is_phone_number_verified','false');
      signUpForm.append('is_email_verified','false');

      if(this.signupForm.value.social_id){
        signUpForm.append('social_id',this.signupForm.value.social_id);
      } else {
        signUpForm.append('password',this.signupForm.value.password);
      }

      this._authService.user_register(signUpForm).then(is_register=>{
        if (is_register) {
          $('#signup-close').click()
          var json = {};
          json['user_id'] = this._authService.user._id;
          json['server_token'] = this._authService.user.server_token;

          if (this.setting_data.is_user_mail_verification) {
            json['email'] = this._authService.user.email
            json['is_email_verified'] = true;
          }

          if (this.setting_data.is_user_sms_verification) {
            json['phone'] = this._authService.user.phone
            json['is_phone_number_verified'] = true
          }
          this._authService.user_verify_otp(json);
          this.signupForm.reset();
        }
      })
  }

  changeCountry(event){
    const idx = this.countries.findIndex(_c=>_c.country_code === event.target.value)
    if(idx !== -1){
      this.minNumberLength = this.countries[idx].minimum_phone_number_length
      this.maxNumberLength = this.countries[idx].maximum_phone_number_length
      this.signupForm.patchValue({
        country_code:this.countries[idx].country_code,
        country_phone_code:this.countries[idx].country_phone_code,
        currency:this.countries[idx].currency_code,
      })
    }

  }

  checkReferral(){
    this.referral_error = "";
    var referral_code = this.signupForm.value.referral_code;
    var country_code = this.signupForm.value.country_code;
    this._commanService.check_referral(country_code,referral_code).then(referral_applied=>{
      if(referral_applied){
        this.is_referral_applied = true;
      }else{
        this.referral_error = "title.referral-is-not-valid";
      }
    });
  }

  // Social Login / Register

  signInWithGoogle(): void {
    this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        this._authService.user_login({
          social_id:user.id,
          password:'',
          username:'',
          login_by:'social',
          uuid:this._cartService.cart_unique_token
        }).then(islogin=>{
          if(islogin){
            $(".signinclose").click();
            this.loginForm.reset();
          }
        });
      }
    })
  }

  signInWithFB(): void {
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        this._authService.user_login({
          social_id:user.id,
          login_by:'social',
          password:'',
          username:'',
          uuid:this._cartService.cart_unique_token
        }).then(islogin=>{
          if(islogin){
            $(".signinclose").click();
            this.loginForm.reset();
          }
        });
      }
    })
  }

  registerWithGoogle() {
    this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        this.is_social_login = true
        this.signupForm.patchValue({
          first_name: user.firstName,
          last_name:user.lastName,
          social_id: user.id,
          login_by: 'social',
          email: user.email
        })
      }
    })
  }

  registerWithFB(){
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user=>{
      if(user){
        console.log(user);      
        this.is_social_login = true
        this.signupForm.patchValue({
          first_name: user.firstName,
          last_name:user.lastName,
          social_id: user.id,
          login_by: 'social',
          email: user.email
        })
      }
    })
  }

  // Forgot Password Flow

  forgotPassword(){
    if(this.forgotPasswordForm.invalid){
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    var phone = this.forgotPasswordForm.value.phone;
    this.helper._loader.isLoading = true;
    this._authService.user_forgot_password({phone:this.forgotPasswordForm.value.phone}).then(isForgot=>{
      $(".forgotclose").click();
      this.forgotPasswordForm.reset();
      if(isForgot){
        this.otpForm.patchValue({
          phone:phone
        })
        $(".custom-model-main").removeClass('model-open');
        $("body").addClass('model-open');
        $('#otp-verification-popup').addClass('model-open');
      }
      this.helper._loader.isLoading = false;
    });
  }

  forgotPasswordVerify(){
    if(this.otpForm.invalid){
      this.otpForm.markAllAsTouched();
      return;
    }
    this.helper._loader.isLoading = true;
    this._authService.user_forgot_password_verify({phone:this.otpForm.value.phone,otp:this.otpForm.value.otp.toString()}).then(verifyResponse=>{
      if(verifyResponse.success){
        $("#otpclose").click();
        this.otpForm.reset();
        this.resetPasswordID = verifyResponse.id
        this.resetPasswordToken = verifyResponse.server_token
        $(".custom-model-main").removeClass('model-open');
        $("body").addClass('model-open');
        $('#set-password-popup').addClass('model-open');
      } else {

        this.is_invalid_otp = true
      }
      this.helper._loader.isLoading = false;
    });
  }

  resetPassword(){
    if(this.resetPasswordForm.invalid){
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    this.password_error = '';
    if(this.resetPasswordForm.value.password !== this.resetPasswordForm.value.confirm_password){
      this.password_error = "title.password-not-match";
      return;
    }

    this._authService.user_reset_password({
      password:this.resetPasswordForm.value.password,
      id:this.resetPasswordID,
      server_token:this.resetPasswordToken
    }).then(isSuccess=>{
      $("#set-password-popup-close").click();
      this.resetPasswordForm.reset();
    });
  }

  resendPassword(){
    this.helper._loader.isLoading = false;
    var phone = this.otpForm.value.phone;
    this._authService.user_forgot_password({phone}).then(()=>{
      this.helper._loader.isLoading = false;
    })
  }

  // Clear App Data

  ngOnDestroy(){
    if(this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if(this.cartSubscription){
      this.cartSubscription.unsubscribe();
    }
  }

  setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 1000);
  }

}
