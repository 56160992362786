export const environment = {
  production: true,
  //API_URL: 'http://18.140.103.7:8000/v3/', //uat
   API_URL: 'http://34.235.122.30:8000/v3/', //uat
  // API_URL: 'https://api.deliver.sg/v3/',
  // API_URL: 'http://18.141.176.79:8000/v3/',
  IMAGE_URL: 'https://deliversgbucket.s3.ap-southeast-1.amazonaws.com/',
   BASE_URL: 'http://34.235.122.30:8000/',
  //BASE_URL: 'http://18.140.103.7:8000/',
  // BASE_URL: 'https://api.deliver.sg/',
  // BASE_URL: 'http://18.141.176.79:8000/',
  IS_USE_AWS : false,
  GOOGLE_KEY: 'AIzaSyBsZwP9rsS3iTBhn65U2lw3mzAWSSuvux8',
  //STRIPE_KEY: 'pk_live_HWIutIrlLPQ6XCfhgMF5Oq7l00s3xfAvOH',
   STRIPE_KEY: 'pk_test_EnoFdMTlFiAKQHqz9LbxgzrJ00LYfRzpxW',
  FIREBASE_CONFIG : {
    apiKey: "AIzaSyBViKhoMt_4-rQ92byigbEanQEAmVJS1aA",
    authDomain: "deliversg-1570451296267.firebaseapp.com",
    databaseURL: "https://deliversg-1570451296267.firebaseio.com",
    projectId: "deliversg-1570451296267",
    storageBucket: "deliversg-1570451296267.appspot.com",
    messagingSenderId: "117663095692",
    appId: "1:117663095692:web:9533a4ca4cd6789be154a9",
    measurementId: "G-42KJX4G47W"
  }
};
